import { useEffect } from 'react';
import {
  BRAND_LOGO,
  BRAND_BLACK_LOGO,
  BRAND_CONSTRICT_LOGO,
  BRAND_LOGIN_LOGO,
  BRAND_REGULATOR,
  BREAD_INDEX,
  BRAND_VIDEO,
  BRAND_THEME,
  BRAND_FAVICON,
  SPREAD_CHARGED_TIME,
  TRAD_ARCHIVE_TIME,
  TRAD_NOTIONAL_VALUE_TIME,
} from '@/constant/brand';

const useBrandHandler = () => {
  const envBrand = process.env.CURRENT_BRAND;
  const CURRENT_BRAND = envBrand?.replaceAll('"', '') || '';

  const isPug: boolean = CURRENT_BRAND === BREAD_INDEX.pug;

  const isAu: boolean = CURRENT_BRAND === BREAD_INDEX.au;

  const isVt: boolean = CURRENT_BRAND === BREAD_INDEX.vt;

  const isStar: boolean = CURRENT_BRAND === BREAD_INDEX.star;

  const getLogo = () => {
    return BRAND_LOGO[CURRENT_BRAND] || '';
  };

  const getBlackLogo = () => {
    return BRAND_BLACK_LOGO[CURRENT_BRAND] || '';
  };

  const getConstrictLogo = () => {
    return BRAND_CONSTRICT_LOGO[CURRENT_BRAND] || '';
  };

  const getLoginLogo = () => {
    return BRAND_LOGIN_LOGO[CURRENT_BRAND] || '';
  };

  const getRegulatorList = () => {
    return BRAND_REGULATOR[CURRENT_BRAND] || [];
  };

  const getBrandVideoUrl = () => {
    return BRAND_VIDEO[CURRENT_BRAND] || '';
  };

  const getMainTheme = () => {
    return BRAND_THEME[CURRENT_BRAND] || '';
  };

  const getFavicon = () => {
    return BRAND_FAVICON[CURRENT_BRAND] || '';
  };

  const getSpreadChargedStartTime = () => {
    return SPREAD_CHARGED_TIME[CURRENT_BRAND] || '';
  };

  const getTradArchiveStartTime = () => {
    return TRAD_ARCHIVE_TIME[CURRENT_BRAND] || '';
  };

  const getTradNotionalValueStartTime = () => {
    return TRAD_NOTIONAL_VALUE_TIME[CURRENT_BRAND] || '';
  };

  useEffect(() => {
    // 设置主题色
    document.documentElement.style.setProperty(
      '--mainTheme',
      getMainTheme()?.token?.colorPrimary || '#3f6ffb'
    );
    if (!isPug) return;
    // 设置浏览器icon
    const iconUrl = getFavicon();
    let favicon: any = document.querySelector('link[rel="icon"]');

    if (favicon !== null) {
      favicon.href = iconUrl;

      favicon = document.createElement('link');

      favicon.rel = 'icon';

      favicon.href = iconUrl;

      document.head.appendChild(favicon);
    }
  }, []);

  return {
    CURRENT_BRAND,
    getLogo,
    getBlackLogo,
    getConstrictLogo,
    getLoginLogo,
    getRegulatorList,
    getBrandVideoUrl,
    getMainTheme,
    getSpreadChargedStartTime,
    getTradArchiveStartTime,
    getTradNotionalValueStartTime,
    isPug,
    isAu,
    isVt,
    isStar,
  };
};

export default useBrandHandler;
